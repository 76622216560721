import React, { useContext } from 'react'
import { FView, FText } from 'components'
import { useMediaMinLG } from 'f-web/src/hooks'
import { ThemeContext } from 'f-web-app'

function SectionLabel({ label }) {
  const { themeColors } = useContext(ThemeContext)
  const isMinLG = useMediaMinLG()

  return (
    <FView mb={isMinLG ? '5rem' : '3rem'} ml={isMinLG ? 10 : 0} relative>
      <FText h4 h2={isMinLG} primary bold>
        {label}
      </FText>
      <FView absolute bottom={-7} w={isMinLG ? 250 : 145} h={isMinLG ? 15 : 10} bg={themeColors.primary} />
    </FView>
  )
}

function FeaturedItem({ imgSrc, title, description }) {
  // const isMinLG = useMediaMinLG()
  // const { themeColors } = useContext(ThemeContext)

  return (
    <FView w={315} maxWidth={'100%'}>
      <FView h={420} justifyCenter>
        <img
          src={imgSrc}
          style={{ objectFit: 'content', height: 'auto', width: '100%', zIndex: 1 }}
          alt="featured item"
        />
      </FView>
      {/* <FView h={isMinLG ? '20rem' : '12.5rem'} bg={themeColors.white}>
        <FView size={'1.5rem'} />
        <FView ph={'1.5rem'}>
          <FText backgroundText h6 h4={isMinLG} bold textCenter heading>
            {title}
          </FText>
          <FView size={'1rem'} />
          <FText backgroundText h7 h5={isMinLG} textCenter heading>
            {description}
          </FText>
        </FView>
      </FView> */}
    </FView>
  )
}

export default function HomeView({ locationId }) {
  const isMinLG = useMediaMinLG()
  const { Home, Components, themeColors, themeImages } = useContext(ThemeContext)

  return (
    <FView bg={themeColors.background} overflowX={'hidden'}>
      <Components.PageHeader />
      <Home.Hero />
      <FView size={isMinLG ? 100 : 50} />
      <FView justifyCenter>
        <FView selfCenter w={isMinLG ? 1024 : 345} maxWidth={'100%'} ml={isMinLG ? 0 : 25}>
          <SectionLabel label={'Featured Items'} />
          <FView row justifyCenter flexWrap={'wrap'} gap={25}>
            <FeaturedItem
              imgSrc={themeImages.feature1}
              // title={'English Muffin'}
              // description={'Classic breakfast sandwich made with sausage, cheese, and scrambled egg.'}
            />
            {/* <FView size={25} /> */}
            <FeaturedItem
              imgSrc={themeImages.feature2}
              // title={'Panini Combo'}
              // description={'A tasty panini made with turkey, bacon, avocado, spinach, and mozzarella.'}
              // isMiddle
            />
            {/* <FView size={25} /> */}
            <FeaturedItem
              imgSrc={themeImages.feature3}
              // title={'Spicy Pork Wrap'}
              // description={'A delicious spicy wrap made with roasted pork, lettuce, cucumber, and rice.'}
            />
          </FView>
        </FView>

        {/* <FView size={isMinLG ? 100 : 50} /> */}

        {/* <FView relative>
          <FView selfCenter column w={isMinLG ? 1024 : 375} ml={isMinLG ? 0 : 25} maxWidth={'100%'}>
            <FView absolute>
              <SectionLabel label={'Promotions'} />
            </FView>
            <FView columnReverse row={isMinLG} zIndex={3} alignCenter>
              <FView alignCenter ph={15}>
                <FText heading black primaryText={isMinLG} h6 h4={isMinLG} bold textCenter>
                  Daily Special
                </FText>
                <FView size={'1.5rem'} />
                <FText heading black primaryText={isMinLG} h7 h5={isMinLG} textCenter lineHeight={1.3}>
                  Try one of our daily special made with fresh ingredients. Add a soup on the side to complete your
                  meal. See online menu for more details.
                </FText>
              </FView>
              <FView size={isMinLG ? '5rem' : '1.5rem'} />
              <img
                src={themeImages.popularItems}
                alt="promotions"
                style={{ objectFit: 'contain', maxWidth: isMinLG ? '100%' : 315, zIndex: 30 }}
              />
              <FView size={!isMinLG && '5.5rem'} />
            </FView>
          </FView>
          <FView
            absolute
            bg={themeColors.primary}
            w={'100vw'}
            h={isMinLG ? '50rem' : '25rem'}
            mt={isMinLG ? '12.5rem' : '10rem'}
            style={{ zIndex: 1 }}
          />
        </FView>

        <FView size={isMinLG ? '10rem' : '5rem'} />
        <FView selfCenter w={isMinLG ? 1024 : 345} maxWidth={'100%'}>
          <SectionLabel label={`Now Taking Online Orders`} />
          <FView selfCenter w={1024} maxWidth={'100%'}>
            <img
              src={themeImages.mobilePromo}
              alt="mobile order promotion"
              style={{ objectFit: 'contain', width: '100%' }}
            />
            <FText heading backgroundText h7 h5={isMinLG} textCenter>
              Order online from our website or by downloading Foodly app from your app store. Start collecting points
              and redeem free items.
            </FText>
          </FView>
        </FView> */}

        <FView size={isMinLG ? '10rem' : '5rem'} />
        <FView selfCenter w={isMinLG ? 1024 : 345} maxWidth={'100%'}>
          <SectionLabel label={`Our Restaurant`} />
          <FView row={isMinLG} alignCenter>
            <img
              src={themeImages.aboutUs}
              style={{ objectFit: 'contain', maxHeight: '35vw', maxWidth: '100%' }}
              alt="about us"
            />
            <FView size={isMinLG ? 50 : 15} />
            <FText backgroundText h7 h5={isMinLG} textCenter={!isMinLG} lineHeight={1.3} style={{ maxWidth: 374 }}>
              We aim to provide the best service with high quality homemade food to our customers. All our dishes are
              made with fresh ingredients. Come visit us at our restaurant in Coquitlam to enjoy one of our delicious
              dishes!
            </FText>
          </FView>
        </FView>

        <FView size={isMinLG ? '10rem' : '5rem'} />
        <FView relative>
          <FView selfCenter w={isMinLG ? 1024 : 375} maxWidth={'100%'}>
            <SectionLabel label={`Our Location`} />
            <FView alignCenter>
              <img
                src={themeImages.ourLocation}
                style={{ objectFit: 'contain', width: '71vw', maxWidth: 1024, zIndex: 1 }}
                alt="our location"
              />
            </FView>
          </FView>
          <FView absolute bottom={-50} h={'34.7vw'} maxHeight={500} w={'100vw'} bg={themeColors.primary} />
        </FView>
        <FView size={75} />
        <FView alignCenter ph={15}>
          <FText heading h5>
            Our restaurant is located on Golden Drive, just off the Trans-Canada Highway in Coquitlam.
          </FText>
        </FView>

        <FView size={isMinLG ? '10rem' : '5rem'} />
        <FView h={1} bg={themeColors.lightGrey} />
        <FView size={isMinLG ? '5rem' : '2.5rem'} />
        <Home.ContactUs
          appStoreUrl={'https://apps.apple.com/us/app/id1476138574'}
          googlePlayUrl="https://play.google.com/store/apps/details?id=tech.foodly.foodlymobile"
        />
        <FView size={'12vw'} maxSize={72} />
        <Home.Footer />
      </FView>
    </FView>
  )
}
