import React, { useContext } from 'react'
import { FView, FText, StoreClosedBanner } from 'f-web-app/components'
import { ThemeContext } from 'f-web-app'
import { useSelector } from 'react-redux'
import { dispatch } from 'f-web-app/store'

export default function SummaryContainerView({
  DeliveryOptionElement,
  isCartEmpty,
  waitTime,
  NotesInputElement,
  SubmitButtonElement,
  PaymentMethodElement,
}) {
  const { themeColors, Cart } = useContext(ThemeContext)
  const orderType = useSelector(dispatch.user.getOrderType)
  if (isCartEmpty) {
    return (
      <FText h4 backgroundText textCenter>
        Cart is empty. <br />
        Add items from the Menu.
      </FText>
    )
  }
  return (
    <FView fill alignCenter maxWidth="100%">
      <FView w={540} maxWidth="100%" bg={themeColors.white} p={'2rem'} rounded shadow25>
        <StoreClosedBanner />
        {DeliveryOptionElement}
        <FView row justifyBetween pr={'5rem'} pl={'1rem'}>
          <FText h7 cardText bold>
            ESTIMATED {orderType ? orderType.toUpperCase() : ''} TIME:
          </FText>
          <FText primary bold h7>
            {waitTime} MIN
          </FText>
        </FView>
        <FView h={1} mv={'1rem'} bg={themeColors.lightGrey} />
        <Cart.Summary.Summary />
        <FView ml="1rem">
          <Cart.Summary.AddTip />
          {PaymentMethodElement}
        </FView>
        <FView mv={'1rem'} ml="1rem">
          <FText bold black h7>
            SPECIAL REQUESTS:
          </FText>
        </FView>
        {NotesInputElement}
        <FView size="1rem" />
        <FText h7 backgroundText>
          * Additional charges may apply for Special Requests
        </FText>
        <FView size="1rem" />
        <FView alignCenter>{SubmitButtonElement}</FView>
      </FView>
    </FView>
  )
}
