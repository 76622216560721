import React, { useContext } from 'react'
import { FView } from 'f-web-app/components'
import { useMediaMinMD } from 'f-web/src/hooks'
import { ThemeContext } from 'f-web-app'

export default function Hero({ appDownloadLink, title }) {
  const { Home, themeImages, themeColors } = useContext(ThemeContext)
  const isMinMD = useMediaMinMD()
  return (
    <>
      <FView maxHeight={670} h={'53.5vw'} relative mt={100}>
        <FView bg={themeColors.primary} absolute left={0} right={0} h={'90%'} />
        <FView row fill zIndex={1} alignCenter>
          <FView size={50} />
          <img
            src={themeImages.heroBG}
            style={{ objectFit: 'contain', maxWidth: 600, width: '41.6vw', alignSelf: 'flex-end' }}
            alt="hero background"
          />
          <FView alignCenter fill>
            <img src={themeImages.heroLogo} alt="hero logo" style={{ objectFit: 'contain', width: '23.6vw' }} />
            {isMinMD && (
              <>
                <FView size={50} />
                <Home.OrderNowButton />
              </>
            )}
          </FView>
        </FView>
      </FView>
      {!isMinMD && (
        <FView alignCenter>
          <FView size={25} />
          <Home.OrderNowButton />
        </FView>
      )}
    </>
  )
}
