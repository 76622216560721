import React, { useContext } from 'react'
import { FButton } from 'f-web-app/components'
import useReactRouter from 'use-react-router'
import { ThemeContext } from 'f-web-app'

export default function OrderNowButton({ label = 'ORDER NOW', heading, textProps }) {
  const { history } = useReactRouter()
  const { Components } = useContext(ThemeContext)

  return (
    <FButton onClick={() => history.push('/menu')}>
      <Components.PaintedButtonView heading={heading} textProps={textProps} label={label} secondary />
    </FButton>
  )
}
