export * from 'f-core/src/images'
export * from 'f-web-app/images'

export const logo = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kimscafe/logo.png'
export const logoBlack = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kimscafe/logo2.png'
export const icon = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/kimscafe/icons-512.png'

export const titleUnderline =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kimscafe/titleUnderline.png'

// HERO
export const heroBG = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/kimscafe/heroBG.png'

export const heroLogo = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kimscafe/heroLogo.png'
export const feature1 =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kimscafe/2024feature1.jpeg'
export const feature2 =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kimscafe/2024feature2.jpeg'
export const feature3 =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kimscafe/2024feature3.jpeg'

export const popularItems =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kimscafe/popularItems.png'
export const ourLocation =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kimscafe/ourLocation.png'

export const mobilePromo =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v1/kimscafe/mobilePromo.png'
export const aboutUs = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kimscafe/aboutUs2.jpg'
// CONTACT US
export const contactUsMap =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kimscafe/contactUsMap.png'
export { default as deliveryLogo } from './deliveryLogo.svg'

// REWARD
export const pointsMeter =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kimscafe/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kimscafe/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/kimscafe/rewardArrowRight.png'
export const rewardBG = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto,q_auto:good/v4/kimscafe/rewardBG.png'

// =======================================================
